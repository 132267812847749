<template>
  <el-dialog
    width="900px"
    :title="
      $t(
        'backoffice.template.add_edit_page.content_versions_section.preview_button'
      )
    "
    :visible.sync="visible"
    :destroy-on-close="true"
    class="template-preview-dialog"
    @close="close"
  >
    <template v-if="setParametersStage || !renderData">
      <parameters-group v-model="parameters"></parameters-group>
      <span slot="footer" class="dialog-footer">
        <base-button :label="$t('general.cancel')" @click="close" />
        <base-button :label="$t('general.confirm')" @click="getPreview" />
      </span>
    </template>
    <template v-else>
      <template v-if="renderData.bodyError || renderData.titleError">
        <base-alert
          v-if="renderData.bodyError && renderData.bodyError.message"
          type="error"
          :closable="false"
          show-icon
        >
          {{ renderData.bodyError.message }}
        </base-alert>
        <br />
        <base-alert
          v-if="renderData.titleError && renderData.titleError.message"
          type="error"
          :closable="false"
          show-icon
        >
          {{ renderData.titleError.message }}
        </base-alert>
      </template>
      <div v-else>
        <h3
          v-if="renderData.renderedTitle"
          v-html="renderData.renderedTitle"
        ></h3>
        <iframe
          v-if="renderData.renderedBody"
          :srcdoc="preview"
          sandbox
          style="border: 1px solid #949494; width: 100%; height: 500px; min-height: 500px;"
        >
        </iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <base-button
          :label="$t('general.button_back.label')"
          @click="setParametersStage = true"
        />
        <base-button :label="$t('general.button_close.label')" @click="close" />
      </span>
    </template>
  </el-dialog>
</template>

<script>
import ParametersGroup from '@/components/Templates/ParametersGroup'
import TemplateParameter from '@/app/classes/TemplateParameter'

export default {
  name: 'TemplatePreview',
  components: { ParametersGroup },
  data () {
    return {
      visible: false,
      templateContentVersion: null,
      template: null,
      parameters: null,
      setParametersStage: true,
      renderData: null
    }
  },
  computed: {
    preview () {
      if (!this.renderData) {
        return null
      }
      const { renderedBody } = this.renderData
      if (this.template.contentType === 'text/plain') {
        return this.filter(renderedBody)
      }

      return renderedBody
    }
  },
  methods: {
    close () {
      this.visible = false
    },
    filter (msg) {
      const breakTag = '<br />'
      msg = (msg + '').replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + breakTag + '$2'
      )
      return msg
    },
    showPreview (template, templateContentVersion) {
      this.template = template
      this.templateContentVersion = templateContentVersion
      return template.getMostRecentParameters({
        onSuccess: ({ parameters }) => {
          this.parameters = Object.entries(parameters).map(
            ([key, value]) => new TemplateParameter({ key, value })
          )
          this.setParametersStage = true
          this.visible = true
        }
      })
    },
    getPreview () {
      const form = {
        title: this.templateContentVersion.title,
        type: this.template.templateType,
        body: this.templateContentVersion.body,
        locale: this.templateContentVersion.locale,
        name: this.template.name,
        parameters: Object.fromEntries(
          this.parameters.map(parameter => parameter.toRenderForm())
        )
      }
      this.$api.template.previewRender(form, {
        onSuccess: res => {
          this.renderData = res
          this.setParametersStage = false
        }
      })
    }
  }
}
</script>
