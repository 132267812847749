import Repository from './Repository'
import Template from '@/app/models/Template'
import api from '@/api'

class TemplateRepository extends Repository {
  constructor (model = Template) {
    super(model)
  }

  get (filter, options = {}) {
    const optionsData = this.addWrapperWithPaginator(options)
    return api.template.get(filter, optionsData)
  }

  create (form, options) {
    return api.template.create(form, options)
  }

  update (form, options) {
    return api.template.update(form, options)
  }
}

export default TemplateRepository
