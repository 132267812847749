import { isArray, isNumber, isObject, isBoolean, uniqueId, toNumber } from 'lodash'

export default class TemplateParameter {
  constructor (data = { value: '', key: '', type: null }) {
    this.key = data.key
    this.type = data.type || TemplateParameter.getType(data.value)
    this.value = this.initValue(data.value, this.type)
    this.id = uniqueId('template_parameter_')
  }

  static types = ['text', 'number', 'boolean', 'list', 'object']

  static getType (value) {
    if (isArray(value)) {
      return 'list'
    }
    if (isObject(value)) {
      return 'object'
    }
    if (isBoolean(value)) {
      return 'boolean'
    }
    if (isNumber(value)) {
      return 'number'
    }
    return 'text'
  }

  initValue (value, type) {
    if (!value) {
      return this.getDefaultTypeValue(type)
    }
    switch (type) {
      case 'object':
        return Object.entries(value).map(([key, value]) => new TemplateParameter({ key, value }))
      case 'list':
        return value.map(value => new TemplateParameter({ value }))
      default:
        return value
    }
  }

  getDefaultTypeValue (type) {
    switch (type) {
      case 'object':
      case 'list':
        return []
      case 'boolean':
        return true
      default:
        return ''
    }
  }

  setType (type) {
    this.value = this.getDefaultTypeValue(type)
    this.type = type
  }

  isType (typesList = []) {
    return typesList.includes(this.type)
  }

  toRenderForm (parentType = '') {
    console.log('toRenderForm')
    console.log(parentType)
    console.log(this)
    let value = null
    if (this.isType(['object'])) {
      value = Object.fromEntries(this.value.map(v => v.toRenderForm(this.type)))
    } else if (this.isType(['list'])) {
      value = this.value.map(v => v.toRenderForm(this.type))
    } else if (this.isType(['number'])) {
      value = toNumber(this.value)
    } else {
      value = this.value
    }
    return parentType === 'list' ? value : [this.key, value]
  }
}
