import api from '@/api'
import Model from './Model'
import TemplateRepository from '@/app/repositories/TemplateRepository'
import { uniq } from 'lodash'
import TemplateContentVersion from '@/app/models/TemplateContentVersion'

class Template extends Model {
  #repository = null
  category = null
  contentType = null
  name = null
  ownerName = null
  ownerOspId = null
  templateContentVersions = []
  templateType = null

  constructor (object = {}) {
    super(object)
    this.ownerOspId = {
      ospId: null
    }
    this.#repository = new TemplateRepository()
    this.initialize(object)
  }

  initialize (object) {
    Object.assign(this, { ...object })
    this.templateContentVersions = (object.templateContentVersions || []).map(v => new TemplateContentVersion(v))
  }

  get languages () {
    return uniq(this.templateContentVersions.map(t => t.locale)).sort().join(', ')
  }

  getCreateForm (addedTemplateVersionsNumber, unsortedTemplateVersions) {
    // ownerName: "Alphabet"
    // ownerOspId: {ospId: "alphabet"}
    if (addedTemplateVersionsNumber && unsortedTemplateVersions) {
      const arrayLength = this.templateContentVersions.length

      const addedTemplates = this.templateContentVersions.slice(arrayLength - addedTemplateVersionsNumber, arrayLength)
      this.templateContentVersions.splice(arrayLength - addedTemplateVersionsNumber, addedTemplateVersionsNumber)

      unsortedTemplateVersions.push(...addedTemplates)

      return {
        ...this,
        templateContentVersions: unsortedTemplateVersions.filter(v => !!v).map(v => {
          return v.getCreateForm()
        })
      }
    }

    return {
      ...this,
      templateContentVersions: this.templateContentVersions.filter(v => !!v).map(v => {
        return v.getCreateForm()
      })
    }
  }

  addVersion (templateVersion = new TemplateContentVersion()) {
    this.templateContentVersions.push(new TemplateContentVersion({ ...templateVersion }))
  }

  create (options) {
    const formData = this.getCreateForm()
    return this.#repository.create(formData, options)
  }

  update (options, addedTemplateVersionsNumber, unsortedTemplateVersions) {
    const formData = this.getCreateForm(addedTemplateVersionsNumber, unsortedTemplateVersions)
    return this.#repository.update(formData, options)
  }

  getMostRecentParameters (options = {}) {
    const form = {
      templateName: this.name,
      templateType: this.templateType
    }
    return api.template.getMostRecentParameters(form, options)
  }

  static async fetch (form) {
    const repository = new TemplateRepository()
    form.ownerIdentifier = {
      ospId: form.ownerIdentifier
    }
    form.pagination = { page: 0, size: 1 }
    const { content } = await repository.get(form)
    const [template] = content
    return template
  }
}

export default Template
