<template>
  <div
    class="template-parameters-group"
    :class="{ 'template-parameters-group--with-offset': offset }"
  >
    <template v-for="(parameter, index) in parameters">
      <parameters-group-item
        v-model="parameters[index]"
        :key="index"
        :group-type="groupType"
        :offset="offset"
        @delete="() => handleDelete(index)"
      ></parameters-group-item>
    </template>

    <base-button
      class="add-circle-button"
      @click="addParameter"
      prepend-icon="ri-add-line"
      type="primary"
      :round="false"
      circle
    ></base-button>
  </div>
</template>

<script>
import { remove } from 'lodash'
import TemplateParameter from '@/app/classes/TemplateParameter'

export default {
  name: 'ParametersGroup',
  components: {
    ParametersGroupItem: () => import('@/components/Templates/ParametersGroupItem')
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    groupType: {
      type: String,
      default: null
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    parameters: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    addParameter () {
      const parameters = [...this.parameters]
      parameters.push(new TemplateParameter())
      this.parameters = parameters
    },
    handleDelete (index) {
      const parameters = [...this.parameters]
      remove(parameters, (v, indx) => indx === index)
      this.parameters = parameters
      // this.key = this.$key()
    }
  }
}
</script>
