<template>
  <page-layout
    :title="$t('backoffice.template.view_all_page.title')"
    :fixed-footer="!!totalPages && totalPages > 1"
    id="templatesViewAllPage"
  >
    <template #header-actions>
      <base-button
        prepend-icon="ri-add-line"
        type="primary"
        :label="$t('backoffice.template.view_all_page.add_template_button')"
        @click="handleAddTemplates"
      />
    </template>
    <div :key="filterKey" class="table-filter">
      <el-form ref="form" :model="form" @submit.prevent.native="() => null">
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('backoffice.template.view_all_page.category_select')"
              ref="category"
              prop="category"
              :items="categoriesList"
              filterable
              v-model="form.category"
              name="category"
            />
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('general.label.type')"
              ref="type"
              prop="type"
              :items="templateTypes"
              v-model="form.templateType"
              :clearable="true"
              name="type"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-input
              class="name"
              ref="name"
              prop="name"
              v-model.trim="form.templateNameLike"
              name="name"
              :label="$t('general.input_label.name')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('general.label.owner')"
              ref="owner"
              prop="owner"
              :items="ownerList"
              v-model="form.ownerNameLike"
              clearable
              filterable
              allow-create
              name="owner"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('general.label.language')"
              ref="language"
              prop="language"
              :items="templateLanguages"
              v-model="form.language"
              :clearable="true"
              name="language"
            ></base-select>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      id="templateTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row clickable"
      @row-click="handleShowDetails"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        :label="$t('backoffice.template.view_all_page.table.template_name')"
        min-width="180"
        sortable="sortColumn"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="info"
        :label="$t('backoffice.template.view_all_page.table.info')"
        width="50"
      >
        <template slot-scope="{ row }">
          <el-popover
            v-if="row.description"
            placement="top"
            trigger="hover"
            width="200"
            :content="row.description"
          >
            <i slot="reference" class="ri-information-line" style="font-size: 20px"></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        prop="ownerName"
        :label="$t('general.label.owner')"
        min-width="120"
        sortable="sortColumn"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="languages"
        :label="$t('backoffice.template.view_all_page.table.languages')"
        min-width="110"
      ></el-table-column>
      <el-table-column
        prop="contentType"
        :label="$t('backoffice.template.view_all_page.table.content_type')"
        min-width="110"
        sortable="sortColumn"
      ></el-table-column>
      <el-table-column
        prop="templateType"
        :label="$t('backoffice.template.view_all_page.table.template_type')"
        min-width="100"
        sortable="sortColumn"
      ></el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="140">
        <template slot-scope="{row}">
          <base-button
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>
          <base-button
            @click.stop="handleShowDetails(row)"
            size="small"
            prepend-icon="ri-pencil-line"
            :label="$t('general.button_label.edit')"
          ></base-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import { debounce, uniqBy } from 'lodash'
import TemplateRepository from '@/app/repositories/TemplateRepository'
import TableLoader from '@/mixins/TableLoader'
import CancelRequest from '@/mixins/CancelRequest'
import { confirmDefaultOptions } from '@/app/helpers'

const defaultSort = { order: 'ascending', prop: 'name' }

const templateRepository = new TemplateRepository()
export default {
  name: 'TemplatesViewAll',
  components: {
    PageLayout
  },
  mixins: [TableLoader, CancelRequest],
  data () {
    return {
      filterKey: '',
      key: '',
      form: {
        category: null,
        templateType: null,
        templateNameLike: null,
        ownerNameLike: null,
        language: null
      },
      pagination: {
        page: 0,
        size: 15,
        sort: defaultSort
      },
      dirtyData: {
        content: [],
        totalPages: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      categoriesList: 'templates/categoriesList',
      owners: 'templates/owners',
      filterFormState: 'templates/getFilterFormState',
      templateLanguages: 'i18n/templateLanguages'
    }),
    tableData () {
      // return []
      return this.dirtyData.content || []
    },
    totalPages () {
      try {
        return this.dirtyData.totalPages || 1
      } catch (e) {
        return 1
      }
    },
    ownerList () {
      return uniqBy(this.owners, 'ownerName').map((owner) => ({
        value: owner.ownerName,
        label: owner.ownerName
      }))
    },
    templateTypes () {
      return [
        {
          value: 'MESSAGE',
          label: this.$t('general.template_types.message')
        },
        {
          value: 'TICKET',
          label: this.$t('general.template_types.ticket')
        }
      ]
    },
    filter () {
      return {
        templateType: this.form.templateType || undefined,
        category: this.form.category || undefined,
        templateNameLike: this.form.templateNameLike || undefined,
        ownerNameLike: this.form.ownerNameLike || undefined,
        language: this.form.language || undefined,
        pagination: {
          ...this.pagination,
          sort: {
            sortOrders: [
              {
                direction: (
                  this.pagination.sort.order || defaultSort.order
                ).toUpperCase(),
                property: this.pagination.sort.prop
              }
            ]
          }
        }
      }
    }
  },
  watch: {
    'pagination.page': debounce(function () {
      this.fetchData()
    }, 200),
    'pagination.sort': debounce(function () {
      this.fetchData()
    }, 200),
    form: {
      deep: true,
      handler: debounce(function () {
        this.fetchData()
      }, 700)
    }
  },
  methods: {
    ...mapActions({
      setFilterFormState: 'templates/setFilterFormState'
    }),
    async fetchData () {
      this.TableLoader_show()
      try {
        await templateRepository.get(this.filter, {
          cancelToken: this.CancelRequest_token(),
          onSuccess: (response) => {
            this.dirtyData = response
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.TableLoader_hide()
    },
    handleAddTemplates () {
      this.TableLoader_show()
      this.$router
        .push({
          name: 'BackofficeTemplatesAdd',
          query: {
            category: this.form.category,
            ...this.$route.query
          }
        })
        .then(
          () => {},
          () => this.TableLoader_hide()
        )
    },
    handleShowDetails ({ category, ownerOspId, name, templateType }) {
      this.TableLoader_show()
      this.$router
        .push({
          name: 'BackofficeTemplatesEdit',
          query: {
            category,
            ownerIdentifier: ownerOspId.ospId,
            templateName: name,
            templateType
          }
        })
        .then(
          () => {},
          () => this.TableLoader_hide()
        )
    },
    handleDelete (template) {
      const title = this.$t(
        'backoffice.template.view_all_page.delete_confirmation.title',
        { templateName: template.name }
      )
      const text = this.$t(
        'backoffice.template.view_all_page.delete_confirmation.description'
      )
      this.$confirm(text, title, {
        ...confirmDefaultOptions
      }).then(() => {
        this.TableLoader_show()
        return this.$api.template.delete(template, {
          successNotification: this.$t(
            'backoffice.template.view_all_page.delete_success_notification',
            { templateName: template.name }
          ),
          onSuccess: () => {
            return this.fetchData()
          },
          onError: () => {
            this.TableLoader_hide()
          }
        })
      })
    },
    sortChange (sort) {
      this.pagination.sort = sort
    }
  },
  mounted () {
    this.form = {
      category: this.form.category || this.categories[0],
      ...this.filterFormState
    }
    this.filterKey = this.$key()
    this.fetchData()
  },
  async beforeRouteEnter (to, from, next) {
    const promises = []
    if (!store.getters['templates/categories'].length) {
      promises.push(store.dispatch('templates/fetchCategories'))
    }
    if (!store.getters['templates/owners'].length) {
      promises.push(store.dispatch('templates/fetchOwners'))
    }
    await Promise.all(promises)
    let category = to.query.category || null
    const categoriesList = store.getters['templates/categories']
    if (!category || !categoriesList.includes(category)) {
      [category] = categoriesList
    }
    next((vm) => {
      vm.form.category = category
    })
  },
  beforeRouteLeave (to, from, next) {
    this.setFilterFormState(this.form)
    next()
  }
}
</script>
