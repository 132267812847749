<template>
  <page-layout
    :title="isEdit ? $t('backoffice.template.add_edit_page.edit_title') : $t('backoffice.template.add_edit_page.title')"
    id="templatesAddPage"
    :has-back-button="true"
    :emit-back="true"
    @back="goBack"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="isEdit ? $t('general.button_label.save_changes') : $t('backoffice.template.view_all_page.add_template_button')"
        :loading="saving"
        @click="handleSave"
      />
    </template>
    <el-form
      :key="key"
      ref="form"
      label-position="top"
      :model="form"
      @submit.prevent.native="() => null"
    >
      <base-section
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <base-select
              :label="$t('backoffice.template.view_all_page.category_select')"
              ref="category"
              prop="category"
              :items="categoriesList"
              filterable
              v-model="form.category"
              name="category"
              :disabled="isEdit"
            />
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('backoffice.template.view_all_page.table.template_name')"
              ref="name"
              prop="name"
              :items="templateNamesList"
              v-model="form.name"
              :validations="$v.form.name"
              filterable
              allow-create
              required
              name="name"
              :disabled="isEdit"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('general.label.owner')"
              ref="owner"
              prop="owner"
              :items="ownerList"
              v-model="form.ownerOspId.ospId"
              :validations="$v.form.ownerOspId.ospId"
              filterable
              required
              name="owner"
              :disabled="isEdit"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('backoffice.template.view_all_page.table.content_type')"
              ref="contentType"
              prop="contentType"
              :items="contentTypeList"
              v-model="form.contentType"
              :validations="$v.form.contentType"
              name="contentType"
              required
              :disabled="isEdit"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-select
              :label="$t('backoffice.template.view_all_page.table.template_type')"
              ref="templateType"
              prop="templateType"
              :items="templateTypesList"
              v-model="form.templateType"
              :validations="$v.form.templateType"
              name="templateType"
              required
              :disabled="isEdit"
            ></base-select>
          </el-col>
          <el-col :span="12">
            <base-input
              type="textarea"
              :label="$t('general.input_label.description')"
              maxlength="140"
              :autosize="{minRows: 5, maxRows: 8}"
              v-model="form.description"
              prop="description"
              name="description"
              :show-word-limit="true"
              :placeholder="$t('user_management.view_app_users_page.add_expense.input_description.placeholder', {number: 140})"
            />
          </el-col>
        </el-row>
      </base-section>
      <base-section>
        <el-row
          type="flex"
          justify="space-between"
        >
          <span v-html="$t('backoffice.template.add_edit_page.content_versions_section.title')"></span>
          <base-button
            type="primary"
            :label="$t('backoffice.template.add_edit_page.content_versions_section.add_version_button')"
            @click="() => addVersion()"
          ></base-button>
        </el-row>
        <br>
        <el-collapse
          :key="collapseKey"
          v-if="form.templateContentVersions.length"
          v-model="activeNames"
        >
          <template v-for="(templateContentVersion, index) in form.templateContentVersions">
            <el-collapse-item
              v-if="templateContentVersion !== undefined"
              :title="(templateContentVersion.locale && languageMap[templateContentVersion.locale]) +
              (templateContentVersion.validFrom && templateContentVersion.locale ? ', ' : '') +
              (templateContentVersion.validFrom ? `valid from ${$dateFormat(templateContentVersion.validFrom, 'dd/MM/yyyy')}` : '')"
              :key="index + 'collapse-item'"
              :name="`${(index + 1)}`"
              :id="'templateContentVersion' + (index + 1)"
            >
              <div style="display: flex; flex-direction: row-reverse; flex-wrap: wrap">
                <div class="buttonGroup">
                  <base-button
                    :label="$t('backoffice.template.add_edit_page.content_versions_section.preview_button')"
                    @click="() => previewVersion(templateContentVersion)"
                  ></base-button>
                  <base-button
                    :label="$t('general.label.delete')"
                    @click="() => deleteVersion(index)"
                  ></base-button>
                  <base-button
                    :label="$t('backoffice.template.add_edit_page.content_versions_section.clone_button')"
                    @click="() => cloneVersion(templateContentVersion)"
                  ></base-button>
                </div>

                <el-row :gutter="24" style="max-width: 424px; margin-right: auto">
                  <el-col :span="12">
                    <base-select
                      :label="$t('general.label.language')"
                      ref="language"
                      prop="language"
                      :items="templateLanguages"
                      :validations="$v.form.templateContentVersions.$each.$iter[index].locale"
                      v-model="templateContentVersion.locale"
                      :clearable="true"
                      :maxWidth="400"
                      name="language"
                      required
                    ></base-select>
                  </el-col>
                  <el-col :span="12">
                    <base-datepicker
                      :label="$t('backoffice.template.add_edit_page.content_versions_section.valid_from_input')"
                      v-model="templateContentVersion.validFrom"
                      type="date"
                      :maxWidth="400"
                      :clearable="true"
                      style="margin-right: 0"
                    ></base-datepicker>
                  </el-col>
                </el-row>
              </div>
              <base-checkbox
                :label="$t('backoffice.template.add_edit_page.content_versions_section.external_input')"
                v-model="templateContentVersion.external"
              ></base-checkbox>
              <template v-if="templateContentVersion.external">
                <base-select
                  :label="$t('backoffice.template.add_edit_page.content_versions_section.template_id_input')"
                  ref="externalTemplateId"
                  prop="externalTemplateId"
                  :items="externalTemplatesList"
                  v-model="templateContentVersion.externalTemplateId"
                  :maxWidth="400"
                  :popper-append-to-body="true"
                  name="externalTemplateId"
                ></base-select>
              </template>
              <template v-else>
                <base-input
                  :label="$t('general.label.title')"
                  maxlength="140"
                  v-model="templateContentVersion.title"
                  prop="title"
                  name="title"
                  :maxWidth="900"
                ></base-input>
                <base-input
                  type="textarea"
                  :label="$t('backoffice.template.add_edit_page.content_versions_section.body_input')"
                  :autosize="{minRows: 5, maxRows: 20}"
                  :validations="$v.form.templateContentVersions.$each.$iter[index].body"
                  v-model="templateContentVersion.body"
                  prop="body"
                  name="body"
                  required
                  :max-width="900"
                ></base-input>
              </template>
            </el-collapse-item>
          </template>
        </el-collapse>
      </base-section>
    </el-form>
    <template-preview
      ref="templatePreview"
    ></template-preview>
  </page-layout>
</template>

<script>
import store from '@/store'
import { required, requiredIf } from 'vuelidate/lib/validators'
import Template from '@/app/models/Template'
import { mapActions, mapGetters } from 'vuex'
import { uniqBy, orderBy, cloneDeep } from 'lodash'
import PageLayout from '@/layouts/PageLayout'
import TemplatePreview from '@/components/Templates/TemplatePreview'
import Api from '@/app/services/ApiService/Api'
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'

export default {
  name: 'TemplatesAdd',
  components: {
    PageLayout,
    TemplatePreview
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      key: null,
      form: new Template(),
      activeNames: [],
      collapseKey: null,
      saving: false,
      dirty: false,
      addedTemplateVersionsNumber: null,
      unsortedTemplateVersions: null
    }
  },
  computed: {
    ...mapGetters({
      categoriesList: 'templates/categoriesList',
      predefinedTemplateNames: 'templates/predefinedTemplateNames',
      contentTypes: 'templates/contentTypes',
      templateTypes: 'templates/templateTypes',
      owners: 'templates/owners',
      externalTemplates: 'templates/externalTemplates',
      templateLanguages: 'i18n/templateLanguages'
    }),
    languageMap () {
      const langs = {}
      this.templateLanguages.map(v => {
        langs[v.value] = v.label
      })
      return langs
    },
    contentTypeList () {
      return this.contentTypes.map(type => ({
        value: type.contentType,
        label: type.label
      }))
    },
    templateNamesList () {
      return this.predefinedTemplateNames(this.form.category).sort().map(name => ({
        value: name,
        label: name
      }))
    },
    externalTemplatesList () {
      return orderBy(this.externalTemplates.map(template => ({
        value: template.templateId,
        label: template.templateName
      })), 'label')
    },
    templateTypesList () {
      return this.templateTypes.map(type => ({
        value: type.templateType,
        label: type.label
      }))
    },
    ownerList () {
      return uniqBy(this.owners, 'owner.ospId').map(owner => ({
        value: owner.owner.ospId,
        label: owner.ownerName
      }))
    },
    isEdit () {
      return this.$route.name === 'BackofficeTemplatesEdit'
    },
    requestOptions () {
      return {
        onSuccess: () => {
          this.dirty = false
          this.$router.push({
            name: 'BackofficeTemplatesViewAll',
            query: {
              category: this.form.category
            }
          })
        },
        onFinally: () => {
          this.saving = false
        }
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      ownerOspId: {
        ospId: {
          required
        }
      },
      contentType: {
        required
      },
      templateType: {
        required
      },
      templateContentVersions: {
        required,
        $each: {
          locale: { required },
          body: {
            required: requiredIf(function (nestedModel) {
              return !nestedModel.external
            })
          }
        }
      }
    }
  },
  watch: {
    'form.category' (v) {
      this.fetchPredefinedTemplateNames(v)
    },
    'form.ownerOspId.ospId' (newVal) {
      const owner = this.ownerList.find(v => v.value === newVal)
      this.form.ownerName = owner ? owner.label : null
    }
  },
  methods: {
    ...mapActions({
      fetchPredefinedTemplateNames: 'templates/fetchPredefinedTemplateNames'
    }),
    handleSave () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        Api.pushMessage(this.$t('backoffice.template.add_edit_page.invalid_field_notification'), 'error', 1000)
        return false
      }
      if (this.isEdit) {
        return this.updateTemplate()
      }
      return this.createTemplate()
    },
    createTemplate () {
      this.saving = true
      return this.form.create({
        successNotification: this.$t('backoffice.template.add_edit_page.create_success_notification'),
        ...this.requestOptions
      })
    },
    updateTemplate () {
      this.saving = true
      return this.form.update({
        successNotification: this.$t('backoffice.template.add_edit_page.edit_success_notification'),
        ...this.requestOptions
      }, this.addedTemplateVersionsNumber, this.unsortedTemplateVersions)
    },
    async addVersion (template = undefined) {
      this.addedTemplateVersionsNumber++
      this.form.addVersion(template)
      const index = this.form.templateContentVersions.length
      this.activeNames.push(`${index}`)
      await this.$sleep(50)
      this.$scrollTo('#templateContentVersion' + index)
    },
    previewVersion (templateContentVersion) {
      this.$refs.templatePreview.showPreview(this.form, templateContentVersion)
    },
    deleteVersion (index) {
      delete this.form.templateContentVersions[index]
      this.collapseKey = this.$key()
    },
    cloneVersion (templateContentVersion) {
      return this.addVersion(templateContentVersion)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.key = this.$key()
      this.$watch('form', {
        deep: true,
        handler () {
          this.dirty = true
        }
      })
    })
    this.unsortedTemplateVersions = cloneDeep(this.form.templateContentVersions)
    this.form.templateContentVersions = orderBy(this.form.templateContentVersions, ['validFrom'], ['desc'])
  },
  async beforeRouteEnter (to, from, next) {
    const promises = []
    if (!store.getters['templates/categories'].length) {
      promises.push(store.dispatch('templates/fetchCategories'))
    }
    if (!store.getters['templates/externalTemplates'].length) {
      promises.push(store.dispatch('templates/fetchExternalTemplates'))
    }
    if (!store.getters['templates/contentTypes'].length) {
      promises.push(store.dispatch('templates/fetchContentTypes'))
    }
    if (!store.getters['templates/templateTypes'].length) {
      promises.push(store.dispatch('templates/fetchTemplateTypes'))
    }
    if (!store.getters['templates/owners'].length) {
      promises.push(store.dispatch('templates/fetchOwners'))
    }
    await Promise.all(promises)

    let template = null
    const activeNames = []
    if (to.name === 'BackofficeTemplatesEdit') {
      template = await Template.fetch(to.query)
    }

    if (!template) {
      let category = to.query.category || null
      const categoriesList = store.getters['templates/categories']
      if (category && categoriesList.includes(category)) {
        await store.dispatch('templates/fetchPredefinedTemplateNames', category)
      } else {
        category = null
      }
      template = new Template({ category })
      template.addVersion()
      activeNames.push('1')
    }

    next(vm => {
      vm.form = template
      vm.activeNames = activeNames
    })
  }
}
</script>
