import Model from './Model'
import { DateTime } from 'luxon'
import { DATE_TIME_ISO } from '@/app/helpers'

class TemplateContentVersion extends Model {
  constructor (object = {
    body: '',
    external: false,
    locale: '',
    title: '',
    validFrom: null,
    externalTemplateId: undefined
  }) {
    super(object)
  }

  getDateTime () {
    const time = DateTime.fromISO(this.validFrom).toFormat(DATE_TIME_ISO)
    return time !== 'Invalid DateTime' ? DateTime.fromISO(this.validFrom).toFormat(DATE_TIME_ISO) : null
  }

  getCreateForm () {
    const form = {
      ...this,
      validFrom: this.getDateTime()
    }
    if (form.external) {
      form.title = undefined
      form.body = undefined
    } else {
      form.externalTemplateId = undefined
    }
    return form
  }
}

export default TemplateContentVersion
